import styled from "styled-components";

export const StyledButton = styled.button<{ color?: string }>`
  box-sizing: border-box; /* Aggiunto box-sizing */
  background: ${(p) => p.color || "#003366"}; /* Default background to blue */
  color: ${(p) => (p.color ? "#003366" : "#fff")}; /* Text color based on background */
  font-size: 1rem;
  font-weight: 700;
  max-width: 180px;
  border: 1px solid #edf3f5;
  border-radius: 10px;
  padding: 13px 20px; /* Aggiunto padding orizzontale */
  cursor: pointer;
  margin-top: 0.625rem;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 3px 6px rgba(0, 51, 102, 0.1); /* Adjusted shadow color */

  display: flex; /* Utilizza Flexbox */
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Centra orizzontalmente */

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 1px solid #70ad47; /* Green border on hover */
    background-color: #70ad47; /* Green background on hover */
  }

  /* Assicurati che i Link mantengano gli stili del bottone */
  text-decoration: none;
  width: auto; /* Rimuove l'allungamento */
`;