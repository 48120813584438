import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled("header")`
  padding: 1rem 0.5rem;
  background-color: #FFFFFF; /* Blue background for header */

  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
`;

export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 981px) {
    display: block;
  }

  display: none;

  svg {
    fill: #70AD47; /* Green fill for the burger icon */
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 981px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: #003366; /* Blue text color */
  font-family: 'Roboto', sans-serif;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1.2rem;
  color: #003366; /* Blue text color */
  font-family: 'Roboto', sans-serif;
  transition: color 0.2s ease-in;
  margin: 0.5rem 2rem;

  @media only screen and (max-width: 620px) {
    margin: 1.25rem 2rem;
  }

  &:hover,
  &:active,
  &:focus {
    color: #70AD47; /* Green text color on hover */
  }
`;

export const Label = styled("span")`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-family: 'Roboto', sans-serif;
`;

export const Outline = styled(MenuOutlined)`
  font-size: 22px;
`;

export const Span = styled("span")`
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  transition: all 0.3s ease-in-out;
  color: #003366; /* White text color */

  &:hover,
  &:active,
  &:focus {
    color: #70AD47; /* Green text color on hover */
    text-underline-position: under;
    text-decoration: none;
  }
`;
