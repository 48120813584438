const routes = [
  {
    path: ["/home", "/home"],
    exact: true,
    component: "Home",
    showHeader: true,
    showFooter: true,
  },
  {
    path: "/form",
    exact: true,
    component: "Form",
    showFooter: false,
  },
  {
    path: "/form2",
    exact: true,
    component: "Form/Form2",
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/form3",
    exact: true,
    component: "Form/Form3",
    showHeader: false,
    showFooter: false,
  },
   {
    path: "/form4",
    exact: true,
    component: "Form/Form4",
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/form5",
    exact: true,
    component: "Form/Form5",
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/form6",
    exact: true,
    component: "Form/Form6",
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/form7",
    exact: true,
    component: "Form/Form7",
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/otp",
    exact: true,
    component: "Form/OtpVerification",
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/terms",
    exact: true,
    component: "Form/Terms",
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/success",
    exact: true,
    component: "Form/Success",
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/error",
    exact: true,
    component: "Form/Error",
    showHeader: false,
    showFooter: false,
  },
  {
    path: "/",
    exact: true,
    component: "ComingSoon",
    showHeader: false,
    showFooter: false,
  },
];

export default routes;
