import React, { createContext, useState } from "react";

export interface FormData {
  // Campi esistenti
  weeklyRent: number;
  selectedOption: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phonePrefix?: string;
  phoneNumber?: string;
  // timeline?: string;
  // objective?: string;
  residentialAddress?: string;
  dateOfBirth?: string;
  investmentProperties: string[];
  // Nuovo campo per il NINO
  nino: string;
  // New field for OTP sent time (timestamp in milliseconds)
  otpSentTime: number | null;

  // Rimozione dei campi documentType, documentNumber e documentState
  // documentType?: string;
  // documentNumber?: string;
  // documentState?: string;
  }
export interface FormContextProps {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

export const FormContext = createContext<FormContextProps | null>(null);

export const FormProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [formData, setFormData] = useState<FormData>({
    weeklyRent: 0,
    selectedOption: 1,
    firstName: "",
    lastName: "",
    email: "",
    phonePrefix: "",
    phoneNumber: "",
    // timeline: "",
    // objective: "",
    residentialAddress: "",
    dateOfBirth: "",
    investmentProperties: [""],
    nino: "", // Inizializzazione del campo nino
    otpSentTime: null, // Initialize the otpSentTime field
    // Rimozione dei campi documentType, documentNumber e documentState
    // documentType: "",
    // documentNumber: "",
    // documentState: "",
  });

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
};