import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import {
  FooterSection,
  Language,
  Para,
  Empty,
  FlexContainer, // Nuovo stile per gestire il flexbox
} from "./styles";

const Footer = ({ t }: { t: TFunction }) => {
  return (
    <FooterSection>
      <Container>
        <FlexContainer justify="space-between">
          <Col>
            <Language>{t("Address")}</Language>
            <Para>Rent$ure Ltd.</Para>
            <Para>128 City Road, London, United Kingdom, EC1V 2NX</Para>
            <Para>Company Registration Number: 15894707</Para>
          </Col>
          <Col>
            <Para>
              <a href="/privacy-policy">{t("Privacy Policy")}</a>
            </Para>
            <Para>
              <a href="/terms-of-service">{t("Terms of Service")}</a>
            </Para>
            <Para>© 2024 Rent$ure. All rights reserved.</Para>
          </Col>
        </FlexContainer>
      </Container>
    </FooterSection>
  );
};

export default withTranslation()(Footer);
